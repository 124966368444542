import React from 'react'
import { Helmet } from 'react-helmet'
import logo from '../assets/img/logo.png'
import './header.scss'

function Header({ title, subtitle }) {
  return (
    <header className='header'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Holiday Club Lomakutsu</title>
        <link rel='canonical' href='' />
      </Helmet>
      <div className='container'>
        <img src={logo} alt='logo' className='logo' />
        <div className='box'>
          <span>{title}</span>
          <span className='price'>{subtitle}</span>
        </div>
      </div>
    </header>
  )
}

export default Header
