import React from 'react';
import './app.scss';
import Header from './header';
import Footer from './footer';
import AppRouter from './appRouter';

function App() {
  return (
    <div className='app'>
      <Header title={`Ystävälle loma, \n sinulle`} subtitle='500€' />
      <AppRouter />
      <Footer />
    </div>
  );
}

export default App;
