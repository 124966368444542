import React from 'react'

const PrivacyPolicy = () => {
  return (
    <main>
      <h1>Tietosuojaseloste</h1>
      <p>
        Holiday Club Resorts Oy:n asiakas- ja markkinointitietojen
        tietosuojaseloste. Tämä tietosuojaseloste käsittää Euroopan Unionin
        tietosuoja-asetuksen mukaisen selosteen käsittelytoimista (jäljempänä
        tietosuojaseloste) edellyttämät tiedot. Noudatamme yhtiössämme
        henkilötietojen käsittelyssä tietosuoja-asetuksen sekä tietosuojalain
        (1050/2018) määräyksiä. Holiday Club Resorts Oy voi ajoittain muuttaa
        tätä tietosuojaselostetta julkaisemalla siitä uuden version.
        Suosittelemme tutustumaan tietosuojaselosteeseemme säännöllisesti.
      </p>
      <br />
      <h2>1 Rekisterinpitäjä</h2>
      <p>
        Holiday Club Resorts Oy (omasta sekä kaikkien Holiday Club
        -konserniyhtiöiden puolesta, jäljempänä Holiday Club)
      </p>
      <p>Hitsaajankatu 22</p>
      <p>00810 Helsinki</p>
      <b>Yhteys:</b>
      <p>Hitsaajankatu 22</p>
      <p>00810 Helsinki</p>
      <p>tietosuoja@holidayclub.fi</p>
      <br />
      <h2>
        2 Henkilötietojen käsittelyn tarkoitus ja käsittelyn oikeusperuste
      </h2>
      <h3>2.1 Asiakasrekisterit</h3>
      <p>
        Holiday Clubilla käsitellään useissa eri yhteyksissä asiakkaiden
        henkilötietoja.&nbsp;
      </p>
      <p>Keskeisimmät asiakasrekisterimme ovat:</p>
      <ol>
        <li>
          Hotellien varausjärjestelmässä ylläpidettävä asiakasrekisteri sekä
        </li>
        <li>
          Viikko- ja Villas-osakekauppojen perusteella ylläpidettävä rekisteri
        </li>
      </ol>
      <p>
        Henkilötietojen käsittely perustuu sopimussuhteeseen, lakiin perustuvaan
        syyhyn tai Holiday Clubin oikeutettuun etuun.
      </p>
      <p>
        Lakiin perustuva käsittelyperuste koskee osakkeenomistajien osalta
        ylläpidettävää osakeluetteloa. Holiday Club toimii useiden Villas- ja
        viikko-osakeyhtiöiden isännöitsijänä ja ylläpitää tästä johtuen
        kiinteistöyhtiöiden lukuun osake- ja osakasluetteloa. Osakeyhtiölaki
        sekä asunto-osakeyhtiölaki (riippuen kumpaa lakia sovelletaan yhtiöön)
        määrittävät jonkin verran näiden tietojen käsittelyä. Isännöitsijän
        roolissa Holiday Club lähettää osakkeenomistajille myös
        kiinteistöyhtiöiden tiedotteita sekä kutsuja yhtiökokouksiin sekä muita
        vastaavia dokumentteja.
      </p>
      <p>
        Lomakutsu-kampanjan kutsujan, eli viikko-osakkeenomistajan, tiedot on
        saatu viikko-osakkeenomistajien postituslistalta. Mahdolliset lisätiedot
        henkilötiedoista tallennetaan palkkion toimitusta varten.
      </p>
      <br />
      <h3>2.2 Markkinointirekisterit</h3>
      <p>
        Markkinoinnissa henkilötietojen käsittely voi tapahtua joko Holiday
        Clubin oikeutettuun etuun tai asiakkaan voimassa olevan suostumuksen
        perusteella. Markkinoinnin osalta Holiday Clubin oikeutettu etu voi
        liittyä liiketoiminnallisiin intresseihin, kuten palveluiden
        laadunvalvontaan, palveluiden kehittämiseen, asiakastyytyväisyyden
        mittaamiseen, henkilöstön kouluttamiseen, lisäpalveluiden tarjoamiseen
        tai suoramarkkinointiin.
      </p>
      <p>
        Sähköinen suoramarkkinointi edellyttää aina asiakkaan antamaa
        suostumusta, jotka hankitaan aina kirjallisesti. Sähköisen
        suoramarkkinoinnin osalta lupa tarvitaan, vaikka asiakas olisikin
        Holiday Clubin asiakas muulla perusteella. Suostumus pyydetään
        kirjallisena ja sen voi halutessaan peruuttaa koska tahansa.
      </p>
      <p>
        Asiakastietojärjestelmien tietoja käytetään myös sähköisten ja
        painettujen kirjeiden, asiakaslehden, osakastapahtumakutsujen (ml.
        kiinteistöyhtiöiden yhtiökokoukset) sekä vastaavien tiedotteiden
        lähettämiseen. Asiakastietojärjestelmien henkilötietoja voidaan käyttää
        myös oikeutetun edun perusteella puhelimitse tapahtuvaan
        suoramarkkinointiin.
      </p>
      <p>
        Lomakampanjassa kutsujan, eli viikko-osakeomistajan, ystävän sähköposti
        tallennetaan sähköpostipalveluun kutsukirjeiden lähetystä varten.
      </p>
      <br />
      <h3>2.3 Evästeet</h3>
      <p>
        IP-osoite- ja evästetietoja keräämme asiakkaista, kun he vierailevat
        Holiday Clubin verkkopalvelussa. Evästetiedot ovat anonymisoituja.
        Evästeiden kerääminen perustuu verkkosivuille tultaessa annettavaan
        suostumukseen tai rekisterin pitäjän oikeutettuun etuun.&nbsp;
      </p>
      <p>
        Voimme käyttää evästeitä mainonnan kohdentamiseen. Kävijälle voidaan
        esimerkiksi yhteistyökumppaniemme verkostoissa näyttää mainoksia
        sellaisista tuotteista, joita hän on selannut verkkokaupassamme.
      </p>
      <br />
      <h2>3 Tietojen luovutukset ja tietojen siirtäminen</h2>
      <p>
        Holiday Club ei julkaise keräämiään henkilötietoja ja noudattaa
        salassapitovelvollisuutta henkilötietojen suhteen, ellei lainsäädäntö
        tai oikeudellisen vaateen laatiminen, esittäminen tai puolustaminen
        muuta edellytä. Osake- ja osakasluettelot ovat julkisia asiakirjoja
        asunto-osakeyhtiölain ja osakeyhtiölain perusteella, joten ne voidaan
        luovuttaa pyynnöstä tai ne voivat olla nähtävillä tietyssä paikassa.
      </p>
      <p>
        Holiday Club ei siirrä tietoja konsernin ulkopuolelle muutoin kuin
        sellaisille sopimuskumppaneille, jotka tuottavat Holiday Clubille
        jotakin tiettyä palvelua tai tukifunktiota. Tällaisia toimijoita voivat
        olla esimerkiksi järjestelmätoimittajat, asiakkuudenhoitoon liittyvät
        palveluntarjoajat, siistimispalveluiden tuottajat, myyntipalveluiden
        tuottajat, turvallisuuspalveluiden tuottajat, perintää hoitavat tahot,
        postipalvelut, markkinointipalvelut, lisäpalveluiden tuottajat sekä muut
        vastaavat yhteistyökumppanit. HolidayClub on tehnyt näiden
        yhteistyökumppaneiden kanssa tietosuojasopimukset.
        Tietosuojasopimuksilla on sovittu siitä, että nämä tahot käsittelevät
        henkilötietoja tietoturvallisella tavalla ja ainoastaan sopimuksen
        tarkoittamaan käyttötarkoitukseen sekä siitä, mikä on tietoturvan taso
        ja miten mahdollisista tietoturvaloukkauksista tulee ilmoittaa.
      </p>
      <br />
      <h2>4 Henkilötietojen säilytysajat</h2>
      <p>
        Markkinointia varten kerätyt tiedot poistetaan rekisteristä sen jälkeen,
        kun henkilö on peruuttanut antamansa suostumuksen. Henkilötietoja
        säilytetään enintään yksi vuosi, jos kyse on markkinointirekisterissä
        olevasta toimimattomasta sähköpostiosoitteesta.
      </p>
      <p>
        Osakkeenomistajarekisterissä henkilötietoja tulee säilyttää niin kauan,
        kun henkilö on osakkeenomistaja ja 10 vuotta sen jälkeen, kun henkilö on
        luopunut osakkeenomistamisesta. Holiday Club säilyttää entisen
        osakkeenomistajan tietoja enintään 11 vuoden ajan.
      </p>
      <p>
        Hotellien ja muiden palveluiden asiakasrekistereistä henkilön tietoja
        säilytetään enintään viisi vuotta siitä, kun asiakas on edellisen kerran
        hankkinut palveluita.&nbsp;
      </p>
      <p>
        Evästeiden avulla kerättyjä tietoja säilytetään evästeen luonteesta
        riippuen enintään kolme vuotta.
      </p>
      <p>
        Verkkolomakkeet, jotka liittyvät markkinointikilpailuun, poistetaan
        tiedot kuuden kuukauden sisällä kilpailun päättymisestä. Kuitenkin, jos
        kyseisellä lomakkeella on annettu suostumus suoramarkkinointiin tai
        verkkomarkkinointiin, säilytetään tieto suostumuksesta siten kuin edellä
        kerrotaan toimittavan suostumuksen osalta. Mikäli lomakkeen tiedot
        liittyvät toimeksiantoon, säilytetään tietoja enintään kuusi vuotta sen
        tilikauden päättymisestä, kun toimeksiannon mukainen palvelu on
        toteutunut (vuokrausajankohta, jälleenmyynti).
      </p>
      <br />
      <h2>5 Rekisterin suojauksen periaatteet</h2>
      <p>
        Holiday Clubin rekistereiden käyttöoikeudet on rajattu sellaisille
        henkilöille, jotka tarvitsevat henkilötietoja työssään.
        Käyttäjäoikeuksien antaminen perustuu Holiday Clubin tekemiin
        määrityksiin, jotka perustuvat yhtiön prosesseihin. Tietojen
        säilytykseen käytettävät tietokannat ja tietoverkot on suojattu
        organisatorisin ja teknisin toimenpitein.&nbsp;
      </p>
      <p>
        Rekisterin valvonta ja suojaus noudattaa EU-alueella sovellettavia
        määräyksiä. Lisäksi kaikkien sopimuskumppaneiden, joilla on pääsy
        henkilötietoihin tai joille tietoja siirretään, on tehty
        tietosuojasopimus tai tietosuojasta on sovittu &nbsp;muussa
        asiakirjassa. Sopimuskumppaneilta edellytetään riittävää tietosuojan
        tasoa.
      </p>
      <br />
      <h2>6 Henkilön oikeus tarkastaa itseään koskevat henkilötiedot</h2>
      <p>
        Jokaisella on oikeus saada tietää Holiday Clubilta, käsitelläänkö häntä
        koskevia henkilötietoja vai ei ja mitä nämä tiedot ovat.
      </p>
      <p>
        Helpoiten itseään koskevat tiedot voi pyytää, täyttämällä Holiday Clubin
        verkkopalvelussa tietopyyntölomakkeen. Lomake löytyy osoitteesta{' '}
        <a
          href='http://www.holidayclub.fi/tietolomake'
          rel='noopener noreferrer'
          target='_blank'
        >
          www.holidayclub.fi/tietolomake
        </a>
        . Halutessaan henkilö voi tehdä tietopyynnön myös henkilökohtaisesti tai
        muulla tavoin kirjallisesti.
      </p>
      <br />
      <h2>
        7 Henkilötietojen oikaiseminen, poistaminen sekä rekisteröidyn oikeus
        pyytää käsittelyn rajoittamista
      </h2>
      <p>
        Mikäli henkilötiedoissa on virheitä, voi henkilö pyytää näiden
        korjaamista. Korjaamme tiedot mahdollisimman pian siitä, kun virheestä
        on tehty ilmoitus. Tietoja voidaan korjata henkilön itsensä tekemän
        ilmoituksen perusteella tai jos asiasta tulee tieto muusta luotettavasta
        lähteestä.
      </p>
      <p>
        Henkilöllä on velvollisuus ilman aiheetonta viivytystä ilmoittaa
        henkilötiedoissaan olevat virheet Holiday Clubille. Ilmoituksen voi
        tehdä esimerkiksi sähköpostitse osoitteeseen{' '}
        <a href='mailto:tietosuoja@holidayclub.fi'>tietosuoja@holidayclub.fi</a>
      </p>
      <p>
        Jokaisella on oikeus saada itseään koskevat henkilötiedot poistetuksi
        rekisteristä (&rdquo;oikeus tulla unohdetuksi&rdquo;) säilytysajan
        päätytyttä, jos henkilötietoja ei enää tarvita niihin tarkoituksiin,
        joita varten henkilötiedot on kerätty tai jos henkilötietojen käsittely
        perustuu suostumukseen ja suostumus peruutetaan. Lisäksi jos
        henkilötietojen käsittely on lain vastaista tai Holiday Club on lain
        mukaan velvoitettu poistamaan tiedot, poistetaan ne.
      </p>
      <br />
      <h2>8 Oikeus kieltää suoramarkkinointi</h2>
      <p>
        Jos henkilötietoja käytetään suoramarkkinointiin, voi henkilö koska
        tahansa vastustaa tietojen käyttämistä markkinointitarkoituksia varten.
        Tällöin henkilötietoja ei saa enää käsitellä tässä käyttötarkoituksessa.
        Mikäli asiakas haluaa kieltää suoramarkkinoinnin, onnistuu se helpoimmin
        lähettämällä kiellon sähköpostitse osoitteeseen{' '}
        <a href='mailto:tietosuoja@holidayclub.fi'>tietosuoja@holidayclub.fi</a>
      </p>
    </main>
  )
}

export default PrivacyPolicy
