import React from "react";
import { FormProvider } from "../../context/formContext";
import Form from "../form/form";
import OffersSection from "../offersSection";
import "./mainView.scss";

const View = () => {
  return (
    <div className="mainView">
      <section className="infoContainer">
        <h1>Ilahduta ystävääsi lomakutsulla</h1>
        <p>
          Viikko-osakkaana sinulla on mahdollisuus kutsua ystäväsi
          hemmoteltavaksi Holiday Club -tutustumislomalle erikoishintaan.
          Ystäväsi pääsee nauttimaan lomasta Caribiassa, Katinkullassa, Kuusamon
          Tropiikissa, Saariselällä, Saimaalla tai Tampereen Kylpylässä sekä
          tutustumaan viikko-osakkeen omistajuuteen Holiday Club -esittelyssä.
        </p>
        <p>
          Saat leiman mobiiliosakaskortillesi, kun kutsumasi ystävä on käynyt
          Holiday Club -esittelyssä. Kun kolme ystävääsi on osallistunut
          esittelyyn, palkitsemme sinut yhden vuorokauden kylpylälahjakortilla,
          joka sisältää aamiaisen ja kylpylän vapaan käytön. Kylpylälahjakortti
          tulee näkyviin mobiiliosakaskortillesi automaattisesti kolmannen
          leiman jälkeen.
        </p>
        <p>
          Jos ystäväsi tekee tutustumisloman aikana osakekaupat, saat 500 euron
          lahjakortin Holiday Clubille.
        </p>
        <p>Hemmottele ystävääsi Holiday Club -lomalla!</p>
      </section>
      <OffersSection />
      <h3>Kampanja on päättynyt.</h3> <br />
      <br />
      {/* <Form /> */}
    </div>
  );
};

const MainView = () => (
  <FormProvider>
    <View />
  </FormProvider>
);

export default MainView;
