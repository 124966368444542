import React from "react"
import "./spinner.scss"
const divs = [] // collector of rendered divs
const count = 12 // how many divs we want

for (let i = 0; i < count; i++) {
  divs.push(<div key={i} className={`loading-circle${i} loading-child`} />)
}

const Spinner = ({ className, size }) => {
  return (
    <div
      className={`spinner ${className ? className : ""}`}
      style={{ width: size, height: size }}
    >
      {divs}
    </div>
  )
}
export default React.memo(Spinner)
