import React from 'react'
import { Link, Location } from '@reach/router'
import './footer.scss'

function Footer() {
	return (
		<Location>
			{({ location }) => (
				<footer>
					<div className='container'>
						<p>Holiday Club Resorts Oy</p>
						<p>
							Puhelin 0300 870 922, ma–pe 9-17, la 9-14
							<br /> Puhelu on lisämaksuton, normaali pvm/mpm.
						</p>
						<p>
							Tarjous on voimassa 31.12.2022 asti (pois lukien 24.–26.12.2022).{' '}
						</p>

						<p>
							Tutustu Holiday Clubin tietosuojaselosteeseen{' '}
							<a href={`${location.origin}/tietosuojaseloste`} target='_blank'>
								tästä.
							</a>
						</p>
					</div>
				</footer>
			)}
		</Location>
	)
}

export default Footer
