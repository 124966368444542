import React from 'react'

const CampaignRules = () => {
	return (
		<main>
			<h1>Kampanjan säännöt</h1>
			<div>
				<p>
					Ystäväkampanjan edut ja ehdot ovat voimassa vain kampanjassa
					mainituissa tutustumislomissa, jotka ystävän tulee varata ja maksaa
					Holiday Club -verkkokaupassa. Tutustumislomatarjoukset ovat voimassa
					31.12.2022 asti (pois lukien 24.–26.12.2022).
				</p>
				<p>
					Tutustumislomalle kutsutun ystävän tulee osallistua tutustumisloman
					aikana noin kaksi tuntia kestävään esittelyyn yhdessä puolisonsa
					kanssa, mikäli on parisuhteessa. Viikko-osakkeen omistaja, eli
					kutsuja, saa 1 kpl yhden vuorokauden Holiday Club kylpylälahjakortin
					sen jälkeen, kun 3 ystävää on osallistunut esittelyyn eli 3 eri
					esittelyä on toteutunut. Lahjakortti oikeuttaa yhteen ilmaiseen
					hotelliyöpymiseen (max. 2 aikuista ja 2 lasta) sisältäen aamiaisen ja
					kylpylän vapaan käytön missä tahansa Holiday Clubin Suomessa
					sijaitsevassa kylpylähotellissa. Kutsuttujen ystävien esittelykäynnit
					kirjataan kutsun lähettäneen osakkaan mobiiliosakaskortin
					leimanäkymään ja kolmannen leiman jälkeen mobiiliosakaskortille
					lähetetään kylpylälahjakortti, joka on voimassa 1 vuoden
					myöntämispäivästä, samalla leimat häviävät leimanäkymästä. Kun leimat
					on vaihdettu kylpylälahjakortiksi, voi osakas ansaita uusia leimoja
					kutsumiensa ystävien esittelykäynneistä.
				</p>
				<p>
					Jos ystävä hankkii tutustumisloman aikana Holiday Club
					-viikko-osakkeen, postitetaan kutsujalle noin kolmen viikon kuluttua
					ostohetkestä lukien 500 euron arvoinen Holiday Club -lahjakortti
					edellyttäen, ettei ystävä ole peruuttanut kauppaa kuluttajasuojalain
					10. luvun perusteella.
				</p>
				<p>
					Lomakutsu-kampanjan kutsujan tiedot on saatu osakkaan
					verkkopalvelusta. Mahdolliset lisätiedot henkilötiedoista tallennetaan
					palkkion toimitusta varten. Kutsujan ystävän sähköposti tallennetaan
					sähköpostipalveluun kutsukirjeiden lähetystä varten.
				</p>
				<p>
					Verottajan ohjeiden mukaisesti lomakutsu-kampanjassa annettava 500
					euron lahjakortti on ansiotuloksi katsottava palkkio. Tästä syystä
					Holiday Club on velvoitettu ilmoittamaan tämän kampanjan yhteydessä
					annetut 500 euron lahjakortit tulorekisteriin ja lahjakortin saajan on
					ilmoitettava lahjakortin arvo veroilmoituksessaan, mikäli se ei jo näy
					esitäytetyllä veroilmoituksella.
				</p>
				<p>
					Lahjakortilla voi maksaa majoituksen kaikissa Suomen Holiday Club
					-kylpylähotelleissa. Se toimii maksuvälineenä myös kylpylähotellien
					palveluissa mm. ravintoloissa, harrasteissa, hemmotteluhoidoissa.
					Lahjakortti on voimassa antamispäivästä lähtien yhden vuoden.
				</p>
				<p>
					Jokainen vahvistettu uusi viikko-osakekauppa oikeuttaa 500 euron
					arvoiseen Holiday Club -lahjakorttiin.
				</p>
				{/* <p>Lomailuaika on 20.5.- 25.6.21 ja 27.6.-29.8.21.</p> */}
			</div>
		</main>
	)
}

export default CampaignRules
