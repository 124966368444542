import React, { useState } from 'react';
import { useForm } from '../../context/formContext';
import { navigate } from '@reach/router';
import { emailValidation, nameValidation, telValidation, termsAcceptedValidation, textFieldValidation } from '../../services/validations';
import Modal from '../modal';
import InviteForm from './inviteForm';
import InviterForm from './inviterForm';
import './form.scss'
import Spinner from '../common/spinner'

function Form() {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [formState, dispatch] = useForm()
  const [loading, setLoading] = useState(false)
  const [serverError, setServerError] = useState(false)
  const [formNotValid, setFormNotValid] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();
    if (loading) {
      return
    }
    if (serverError) {
      setServerError(false)
    }
    setFormNotValid(false)
    const errors = validateForm()
    const isNotValid = hasErrors(errors)
    // set errors to context
    dispatch({
      type: 'setErrors',
      payload: errors
    })
    if (isNotValid) {
      setFormNotValid(true)

      return
    }
    setLoading(true)

    const { fname, lname, email, tel, notes, termsAccepted } = formState.inviter

    const body = {
      inviterFirstName: fname,
      inviterLastName: lname,
      inviterEmail: email,
      notes,
      inviterPhoneNumber: tel,
      rulesAccepted: termsAccepted,
      inviteesEmails: formState.invitees.emails
    }

    fetch(`${process.env.REACT_APP_API_URL}/invite`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(response => {
      setLoading(false)
      console.log(response)
      navigate('/success')
    }).catch((err) => {
      setLoading(false)
      setServerError(true)
      console.error('Error while sending formdata', err)
    })

  };

  /**
   * Validates formState's input values and returns result
   */
  const validateForm = () => {
    const { fname, lname, email, tel, termsAccepted, notes } = formState.inviter
    const { emails: inviteeEmails } = formState.invitees

    const inviteeErrors = inviteeEmails.map((value) => (
      emailValidation(value)
    ))
    return {
      inviter: {
        notes: textFieldValidation('Tekstikenttä', notes),
        fname: nameValidation('Etunimi', fname),
        lname: nameValidation('Sukunimi', lname),
        email: emailValidation(email),
        tel: telValidation(tel),
        termsAccepted: termsAcceptedValidation(termsAccepted)
      },
      invitees: inviteeErrors
    }
  }
  /**
   * Check if errors object passed the validation
   * @param {Object} errors 
   */
  const hasErrors = (errors) => {
    return Boolean(Object.values(errors.inviter).find(value => value !== null)) || Boolean(errors.invitees.find(value => value !== null))
  }

  const openPreview = () => {
    const errors = validateForm()
    const isNotValid = hasErrors(errors)
    // set errors to context
    dispatch({
      type: 'setErrors',
      payload: errors
    })
    if (isNotValid) {
      console.log("form has errors")
      setFormNotValid(true)

      return
    }
    setPreviewOpen(true)
  }

  return (
    <div className='formContainer'>
      <form className='form' onSubmit={submitForm} noValidate>
        <h2>Lähetä ystävällesi lomakutsu tästä</h2>
        <InviteForm />
        <hr />
        <InviterForm />
        <button disabled={loading} type='submit'>{loading ? <Spinner size='25px' /> : "Lähetä kutsu!"}</button>
        <button type='button' onClick={openPreview}>Esikatsele ja lähetä</button>
        <p className='error'>{formNotValid && 'Tarkista lomakkeen kentät ja yritä uudelleen'}</p>
        <p className='error'>{serverError && 'Tapahtui odottamaton virhe. Yritä myöhemmin uudestaan.'}</p>
        <Modal loading={loading} serverError={serverError} isOpen={previewOpen} closeModal={() => setPreviewOpen(false)} submitForm={submitForm} />
      </form>
    </div>
  );
}

export default Form;
