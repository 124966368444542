import React from 'react'
import Tampere1Img from '../assets/img/Tampere-Caribia-ja-Saimaa_kuva_500x500.jpg'
import Tampere2Img from '../assets/img/Tampere-Caribia-ja-Saimaa_teksti_500x500_elokuu.jpg'
import Kuusamo3Img from '../assets/img/Kuusamo_kuva_500x500.jpg'
import Kuusamo4Img from '../assets/img/Kuusamo_teksti_500x500_elokuu.jpg'
// import Katinkulta5Img from '../assets/img/Katinkulta_kuva_500x500.jpg'
// import Katinkulta6Img from '../assets/img/Katinkulta_teksti_500x500.jpg'

import './offersSection.scss'

const OffersSection = () => {
	return (
		<section className='offersSection'>
			<h2 className='offers_header'>
				Ystäväsi voi valita seuraavista kohteista:
			</h2>
			<div className='offers'>
				<div
					className='offer'
					title='Tampere, Caribia ja Saimaa 2 vrk alk. 99 € /perhe'
				>
					<div className='imageContainer'>
						<img alt='Tampere, Caribia, Saimaa kuva' src={Tampere1Img} />
					</div>
					<div className='imageContainer'>
						<img
							alt='Tampere, Caribia ja Saimaa 2 vrk alk. 99 €'
							src={Tampere2Img}
						/>
					</div>
				</div>
				<div
					className='offer'
					title='Kuusamo ja Saariselkä 3 vrk alk. 120 €/perhe'
				>
					<div className='imageContainer'>
						<img alt='Kuusamo ja Saariselkä kuva' src={Kuusamo3Img} />
					</div>
					<div className='imageContainer'>
						<img
							alt='Kuusamo ja Saariselkä 3 vrk alk. 120 €/perhe'
							src={Kuusamo4Img}
						/>
					</div>
				</div>

				<p className='paragraph_afterimage'>
					{' '}
					<br />
					Tutustumisloma sisältää majoituksen (maks. kaksi aikuista ja kaksi
					lasta, Caribiassa kaksi aikuista ja yksi lapsi), aamiaiset ja kylpylän
					vapaan käytön. Erikoistarjous on voimassa vain viikko-osakkaiden
					kutsumille ystäville 31.12.2022 asti (pois lukien 24.–26.12.2022).
				</p>
			</div>
		</section>
	)
}

export default OffersSection
