import React from 'react'

function SuccessView() {
  return (
    <div className='mainView'>
      <section className='infoContainer'>
        <h1>Kiitos osallistumisesta!</h1>
        <p>
        Olemme lähettäneet lomakutsun ystävällesi. 
        Muistathan lähettää vähintään 3 kutsua, sillä jokaisesta 
        tutustumislomalla toteutuneesta esittelykäynnistä lähetämme 
        mobiiliosakaskortillesi leiman. 
        Kolmesta leimasta palkitsemme sinut kylpylälahjakortilla. 
        </p>
      </section>
    </div>
  )
}

export default SuccessView
