import React, { createContext, useReducer, useContext } from 'react'

const FormStateContext = createContext()
const FormDispatchContext = createContext()

function formReducer(state, action) {
  switch (action.type) {
    case 'addInvitee': {
      const emails = [...state.invitees.emails, '']
      const emailErrors = [...state.invitees.emailErrors, null]
      return {
        ...state,
        invitees: {
          emails,
          emailErrors
        }
      }
    }
    case 'removeInvitee': {
      const emails = [...state.invitees.emails]
      emails.splice(action.payload, 1)
      const emailErrors = [...state.invitees.emailErrors]
      emailErrors.splice(action.payload, 1)
      return {
        ...state, invitees: {
          emails,
          emailErrors
        }
      }
    }
    case 'updateInvitee': {
      const emails = [...state.invitees.emails]
      emails[action.payload.index] = action.payload.value
      return {
        ...state,
        invitees: {
          ...state.invitees,
          emails
        }
      }
    }
    case 'updateInviter': {
      const inviter = { ...state.inviter }
      inviter[action.payload.field] = action.payload.value
      return {
        ...state,
        inviter
      }
    }
    case 'setErrors': {
      return {
        ...state,
        inviter: {
          ...state.inviter,
          errors: action.payload.inviter
        },
        invitees: {
          ...state.invitees,
          emailErrors: action.payload.invitees
        }
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const INITIAL_STATE = {
  invitees: {
    emails: [''],
    emailErrors: [null],
  },
  inviter: {
    notes: 'Hei, Holiday Clubin viikko-osakkaana sain mahdollisuuden tarjota ystävälleni huippuedullisen loman ja ajattelin sinua. Kutsun sinut rentouttavalle tutustumislomalle, jonka aikana saat kuulla myös Holiday Clubin viikko-osakkeista. Viihdyt näissä kohteissa varmasti!',
    fname: '',
    lname: '',
    email: '',
    tel: '',
    termsAccepted: false,
    errors: {
      notes: null,
      fname: null,
      lname: null,
      email: null,
      tel: null,
      termsAccepted: null,
    }
  }
}

const FormProvider = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE)

  return (
    <FormStateContext.Provider value={state}>
      <FormDispatchContext.Provider value={dispatch}>
        {children}
      </FormDispatchContext.Provider>
    </FormStateContext.Provider>
  )
}

const useFormState = () => {
  const context = useContext(FormStateContext)
  if (context === undefined) {
    throw new Error('useFormState must be used within a FormProvider')
  }
  return context
}

const useFormDispatch = () => {
  const context = useContext(FormDispatchContext)
  if (context === undefined) {
    throw new Error('useFormDispatch must be used within a FormProvider')
  }
  return context
}

const useForm = () => [useFormState(), useFormDispatch()]

export { FormProvider, useForm }

