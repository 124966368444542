import React from 'react'
import { Router } from '@reach/router'
import MainView from './views/mainView'
import SuccessView from './views/success'
import CampaignRules from './views/campaignRules'
import PrivacyPolicy from './views/privacyPolicy'

const AppRouter = () => (
  <main>
    <Router>
      <MainView path="/" />
      <CampaignRules path="/kampanjan-saannot" />
      <PrivacyPolicy path="tietosuojaseloste" />
      <SuccessView path='success' />
    </Router>
  </main>
)

export default AppRouter